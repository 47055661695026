export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>We talk about...</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                  <br/>
                </div> 
              </div>
            </div>  
          
          </div>
        </div>
        <br/>
        <br/>
        <div className="about-text"><h3><i className="fa fa-hand-o-right" aria-hidden="true"></i> If you want to stop by, feel free to check out our next event on<a target="_blank" rel="noopener noreferrer" href="https://www.meetup.com/de-DE/doing-good-together-dusseldorf/events/"> Meetup </a><i className="fa fa-calendar" aria-hidden="true"></i> or join our <a target="_blank" rel="noopener noreferrer" href="https://chat.whatsapp.com/BbVAaho2tbnA9l3IsDb7tT"> WhatsApp Group </a><i className='fa fa-phone'></i></h3></div>
      </div> 
    </div>
  );
};
